import React, { useEffect, useState } from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import { FaDiscord } from 'react-icons/fa';

const MiddlemanPage = () => {
  const [deal, setDeal] = useState('');
  const [totalWorth, setTotalWorth] = useState(10);
  const [otherUserID, setOtherUserID] = useState('');
  const [reportStatus, setReportStatus] = useState('');
  const [deals, setDeals] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: 'date',
    direction: 'desc'
  });

  useEffect(() => {
    const fetchDeals = async () => {
      try {
        const response = await fetch('https://api.wildismcoins.net/deals');
        const data = await response.json();
        if (data.status === 'OK') {
          const sortedDeals = [...data.deals].sort((a, b) => {
            if (!a.createdAt && !b.createdAt) return 0;
            if (!a.createdAt) return 1;
            if (!b.createdAt) return -1;
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
          setDeals(sortedDeals);
        }
      } catch (error) {
        console.error('Error fetching deals:', error);
      }
    };

    fetchDeals();
  }, []);

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });

    const sortedDeals = [...deals].sort((a, b) => {
if (key === 'value') {
        return direction === 'asc'
          ? parseFloat(b.value) - parseFloat(a.value)
          : parseFloat(a.value) - parseFloat(b.value);
      }
      if (key === 'date') {
        const dateA = a.createdAt ? new Date(a.createdAt) : new Date();
        const dateB = b.createdAt ? new Date(b.createdAt) : new Date();
        return direction === 'asc'
          ? dateB - dateA
          : dateA - dateB;
      }
      return direction === 'asc'
        ? (a[key] || '').localeCompare(b[key] || '')
        : (b[key] || '').localeCompare(a[key] || '');
    });
    setDeals(sortedDeals);
  };

  const calculateMiddlemanFee = () => {
    const effectiveWorth = totalWorth < 10 ? 10 : totalWorth;
    if (effectiveWorth < 250) {
      return 10;
    } else {
      const feePercentage = 0.04;
      return effectiveWorth * feePercentage;
    }
  };

  const handleTotalWorthChange = (e) => {
    const value = parseFloat(e.target.value);
    setTotalWorth(value);
  };

  const handleSliderChange = (e) => {
    const value = parseInt(e.target.value);
    setTotalWorth(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!deal.trim() || !otherUserID.trim()) {
      setReportStatus('Please fill in all required fields.');
      return;
    }

    try {
      const response = await fetch('https://api.wildismcoins.net/ticket', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({
          type: 'middleman',
          deal: deal,
          value: totalWorth.toString(),
          otheruserid: otherUserID,
        }),
      });

      const data = await response.json();
      setReportStatus(response.ok ? data.message : `Error (${response.status}): ${data.message}`);
    } catch (error) {
      setReportStatus('Error submitting report: Invalid server response');
    }
  };

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(value);
  };

  const hasUserCookie = document.cookie.includes('user=');

  return (
    <div className="flex flex-col min-h-screen bg-background">
      <Header />

      <main className="container mx-auto px-4 md:px-8 py-12 flex-grow">
        <section className="text-center mb-16">
          <h2 className="text-5xl md:text-6xl font-bold mb-6 text-indigo-400">
            Middleman Request
          </h2>
          <p className="text-lg md:text-xl text-gray-300 mb-10">
            Secure your transactions with our trusted middleman service
          </p>
        </section>

        <section className="grid md:grid-cols-2 gap-8 mb-16">
          <div className="bg-gray-900/50 backdrop-blur-sm rounded-2xl p-8 border border-indigo-500/20">
            <h3 className="text-3xl font-bold mb-6 text-indigo-400">Calculate Fee</h3>
            <div className="space-y-6">
              <input
                type="range"
                min={10}
                max={10000}
                step={10}
                value={totalWorth}
                onChange={handleSliderChange}
                className="w-full h-2 rounded-lg appearance-none cursor-pointer bg-gray-700"
              />
              <div className="flex justify-between text-gray-300">
                <span>$10</span>
                <span>$10,000</span>
              </div>
              <input
                type="number"
                value={totalWorth}
                onChange={handleTotalWorthChange}
                className="w-full bg-gray-800 text-white py-4 px-6 rounded-xl text-lg text-center border border-gray-700 focus:border-indigo-500 outline-none transition"
                min="0"
                step="1"
              />
              <div className="space-y-2">
                <p className="text-xl text-gray-300">Selected Amount: <span className="text-indigo-400">{formatCurrency(totalWorth)}</span></p>
                <p className="text-xl text-gray-300">Middleman Fee: <span className="text-indigo-400">{formatCurrency(calculateMiddlemanFee())}</span></p>
              </div>
            </div>
          </div>

          {hasUserCookie ? (
            <div className="space-y-8">
              <div className="bg-gray-900/50 backdrop-blur-sm rounded-2xl p-8 border border-indigo-500/20">
                <h3 className="text-3xl font-bold mb-6 text-indigo-400">Deal Details</h3>
                <div className="space-y-6">
                  <input
                    type="text"
                    placeholder="Deal description"
                    value={deal}
                    onChange={(e) => setDeal(e.target.value)}
                    className="w-full bg-gray-800 text-white py-4 px-6 rounded-xl text-lg border border-gray-700 focus:border-indigo-500 outline-none transition"
                    required
                  />
                  <input
                    type="text"
                    placeholder="Other user ID"
                    value={otherUserID}
                    onChange={(e) => setOtherUserID(e.target.value.replace(/\D/g, ''))}
                    className="w-full bg-gray-800 text-white py-4 px-6 rounded-xl text-lg border border-gray-700 focus:border-indigo-500 outline-none transition"
                    required
                  />
                </div>
              </div>
              <button
                className="w-full bg-indigo-600 hover:bg-indigo-700 text-white py-4 px-8 rounded-xl text-lg font-semibold transform hover:scale-105 transition-all duration-200 shadow-lg"
                onClick={handleSubmit}
              >
                Submit Request
              </button>
              {reportStatus && (
                <p className={`text-lg text-center ${reportStatus.includes('Error') ? 'text-red-400' : 'text-green-400'}`}>
                  {reportStatus}
                </p>
              )}
            </div>
          ) : (
            <div className="bg-gray-900/50 backdrop-blur-sm rounded-2xl p-8 border border-indigo-500/20 flex flex-col items-center justify-center text-center">
              <p className="text-lg text-gray-300 mb-8">
                Connect with Discord for a seamless experience. We'll automatically set up your account and create your ticket.
              </p>
              <a
                href="https://discord.com/oauth2/authorize?client_id=1281947269284761660&redirect_uri=https%3A%2F%2Fapi.wildismcoins.net%2Fcallback&response_type=code&scope=identify%20guilds.join"
                className="bg-[#5865F2] hover:bg-[#4752C4] text-white py-4 px-8 rounded-xl text-lg font-semibold flex items-center gap-3 transform hover:scale-105 transition-all duration-200 shadow-lg mb-8"
              >
                <FaDiscord size={24} />
                Login with Discord
              </a>

              <div className="mt-4 pt-4 border-t border-gray-700 w-full">
                <p className="text-gray-300 mb-4">
                  Alternatively, join our Discord server directly:
                </p>
                <a
                  href="https://discord.gg/fmPqM4MWA2"
                  className="bg-gray-800 hover:bg-gray-700 text-white py-4 px-8 rounded-xl text-lg font-semibold inline-flex items-center gap-3 transform hover:scale-105 transition-all duration-200 shadow-lg"
                >
                  <FaDiscord size={24} />
                  Join Official Discord Server
                </a>
              </div>
            </div>
          )}
        </section>

        <section className="mt-16">
          <div className="bg-gray-900/50 backdrop-blur-sm rounded-2xl p-8 border border-indigo-500/20">
            <div className="flex justify-between items-center mb-8">
              <h2 className="text-4xl font-bold text-indigo-400">Recent Deals</h2>
              <select
                className="bg-gray-800 text-gray-300 py-2 px-4 rounded-lg border border-gray-700 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 outline-none"
                onChange={(e) => handleSort(e.target.value)}
              >
                <option value="date">Most Recent</option>
                <option value="value">Highest Value</option>
              </select>
            </div>
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="border-b border-gray-700">
                    <th className="p-4 text-left text-gray-300">Deal ID</th>
                    <th className="p-4 text-left text-gray-300">Platform</th>
                    <th className="p-4 text-left text-gray-300">Seller ID</th>
                    <th className="p-4 text-left text-gray-300">Buyer ID</th>
                    <th className="p-4 text-left text-gray-300">Date</th>
                    <th className="p-4 text-left text-gray-300">Deal Value</th>
                    <th className="p-4 text-left text-gray-300">Description</th>
                  </tr>
                </thead>
                <tbody>
                  {deals.map((deal, index) => (
                    <tr 
                      key={deal.id} 
                      className={`border-b border-gray-800 hover:bg-gray-800/50 transition-colors ${
                        index % 2 === 0 ? 'bg-gray-900/30' : ''
                      }`}
                    >
                      <td className="p-4 text-gray-300">{deal.id}</td>
                      <td className="p-4 text-gray-300">{deal.platform || 'Discord'}</td>
                      <td className="p-4 text-gray-300">{deal.seller}</td>
                      <td className="p-4 text-gray-300">{deal.buyer}</td>
                      <td className="p-4 text-gray-300">{formatDate(deal.createdAt)}</td>
                      <td className="p-4 text-indigo-400 font-medium">{formatCurrency(deal.value)}</td>
                      <td className="p-4 text-gray-300">{deal.info}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default MiddlemanPage;